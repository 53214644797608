import React from "react"

import "@styles/styles.scss"
import SEO from "@components/common/seo"

import ApplyForFunding from "@components/views/apply-for-funding/apply-for-funding"


const ApplyForFundingPage = () => (
  <section>
    <SEO title="Apply For Funding" />
    <ApplyForFunding />

  </section>
)

export default ApplyForFundingPage;