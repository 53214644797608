import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import { Row, Col, Button, Form } from "react-bootstrap"
import Image from "@components/common/image"
import DragAndDrop from "@components/common/drag-and-drop"

const steps = {
  step1: {
    question: "Q1. What is the nature of your business?",
    options: [
      "E-commerce",
      "Fintech",
      "Ed- Tech",
      "Health-Tech",
      "Food-Tech",
      "Agri-Tech",
      "SaaS",
      "Supply Chain Technology",
      "Artificial Intelligence",
      "Augmented Reality",
      "Enterprise Technology",
      "Cybersecurity",
      "SME",
      "Others",
    ],
    isMultiple: false,
    fields: [{ name: "Mention Other", type: "text", placeholder: "Comments" }],
  },
  step2: {
    question: "Q2. What is your team size?",
    options: ["1-5", "5-10", "10-20", "20+"],
    isMultiple: false,
    fields: [],
  },
  step3: {
    question: "Q3. Where is your Target Market?",
    options: ["Middle East / Africa", "Europe", "Americas", "Asia Pacific"],
    isMultiple: true,
    fields: [],
  },
  step4: {
    question:
      "Q4. Do you have a minimum viable product or prototype with proof of concept?",
    options: ["Yes", "No"],
    isMultiple: false,
    fields: [],
  },
  step5: {
    question: "Q5. Do you have initial revenue traction?",
    options: ["Yes", "No"],
    isMultiple: false,
    fields: [],
  },
  step6: {
    question: "Q6. Have you already raised some money for your business?",
    options: ["Yes", "No"],
    isMultiple: false,
    fields: [],
  },
  step7: {
    question: "Q7. Do you have a co-founder?",
    options: ["Yes", "No"],
    isMultiple: false,
    fields: [],
  },
  step8: {
    question: "Q8. What is the size of your current funding round?",
    options: [
      "$250k – $500k",
      "$500k - $1 million",
      "$1 million - $2 million",
      "$2 million +",
    ],
    isMultiple: false,
    fields: [],
  },
  step9: {
    question: "Q9. Minimum capital required?",
    label: "$",
    placeholder: "Mention amount",
    options: [],
    isMultiple: false,
    fields: [],
  },
  step10: {
    question: "Q10. Maximum capital required?",
    label: "$",
    placeholder: "Mention amount",
    options: [],
    isMultiple: false,
    fields: [],
  },
  step11: {
    question: "Q11. Pre-Money valuation?",
    label: "$",
    placeholder: "Mention amount",
    options: [],
    isMultiple: false,
    fields: [],
  },
  step12: {
    question: "Q12. Amount already committed by other investors?",
    label: "$",
    placeholder: "Mention amount",
    options: [],
    isMultiple: false,
    fields: [],
  },
  step13: {
    question: "Q13. Previous amount raised?",
    label: "$",
    placeholder: "Mention amount",
    options: [],
    isMultiple: false,
    fields: [],
  },
  step14: {
    question: "Q14. Minimum ticket size?",
    options: [
      "$50k - $100k",
      "$100k - $200k",
      "$200k - $500k",
      "$500k - $1 million",
      "$1 million +",
    ],
    isMultiple: false,
    fields: [],
  },
  step15: {
    question: "Q.15 Tell us more about your business.",
    options: [],
    isMultiple: false,
    fields: [],
  },
}

const QuestionForm = props => {
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({})
  const [uploadError, setUploadError] = useState({
    pitch_deck: "",
    business_plan: "",
    financial_model: "",
  })

  useEffect(() => {
    if (props.formData) {
      setFormData(props.formData)
    }
  }, [props.formData])

  const handleFormSubmit = submit => {
    if (props.submit) {
      props.submit(formData, submit)
    }
  }

  let form = {}
  if (steps["step" + step]) {
    form = steps["step" + step]
  }

  const handleFormChange = (key, value, multi) => {
    let fd = formData
    let v = fd[key]
    if (multi && !v) {
      v = []
    }

    if (multi && v.length > 0) {
      if (v.includes(value)) {
        v = v.filter(i => i !== value)
      } else {
        v.push(value)
      }
    } else if (multi && v.length === 0) {
      v.push(value)
    }

    if (!multi) {
      v = value
    }
    fd[key] = v
    setFormData(Object.assign({}, fd))
  }

  const handleStep = direction => {
    let i = step

    if (direction === "next") {
      i++
			ReactGA.event({
				category: 'steps-form',
				action: `step${i} completed`
			})
    } else {
      i--
    }
    if (i < 1) {
      i = 1
      return handleFormSubmit(0)
    }
    if (i > 15) {
      i = 15
      return handleFormSubmit(1)
    }
    setStep(i)
  }

  const renderOptions = () => {
    if (!form.options) {
      return null
    }
    if (form.options.length === 0) {
      return null
    }
    let selected = formData[form.question]
    if (form.isMultiple && !selected) {
      selected = []
    }

    let col = form.options.length > 6 ? 4 : 6

    return (
      <Row
        className="options"
        style={{
          maxWidth: col === 6 ? "500px" : "none",
          margin: col === 6 ? "0 auto" : "none",
        }}
      >
        {form.options.map((o, i) => {
          if (form.isMultiple) {
            return (
              <Col
                xl={col}
                lg={6}
                md={6}
                onClick={e => handleFormChange(form.question, o, 1)}
              >
                <Form.Check
                  custom
                  className={selected.includes(o) ? "active" : ""}
                  checked={selected.includes(o) ? "active" : ""}
                  type={"checkbox"}
                  id={`custom-${step}-${i}`}
                  name={`custom-${step}`}
                  label={o}
                  key={`custom-${step}-${i}`}
                  onChange={e => handleFormChange(form.question, o, 1)}
                />
              </Col>
            )
          } else {
            return (
              <Col
                xs={form.options.length === 2 ? 6 : 12}
                xl={col}
                lg={6}
                md={6}
                onClick={e => handleFormChange(form.question, o, 0)}
              >
                <Form.Check
                  custom
                  className={selected === o ? "active" : ""}
                  checked={selected === o ? "active" : ""}
                  type={"radio"}
                  id={`custom-${step}-${i}`}
                  name={`custom-${step}`}
                  label={o}
                  key={`custom-${step}-${i}`}
                  onChange={e => handleFormChange(form.question, o, 0)}
                />
              </Col>
            )
          }
        })}
      </Row>
    )
  }

  const renderExtraForm = () => {
    return form.fields.map((f, i) => (
      <Form.Group key={"ef-" + i}>
        <Form.Label>{f.name}</Form.Label>
        <Form.Control
          value={formData[f.name] ? formData[f.name] : ""}
          size="lg"
          type={f.type}
          placeholder={f.placeholder}
          onChange={e => handleFormChange(f.name, e.target.value, 0)}
        />
      </Form.Group>
    ))
  }

  const renderInput = () => {
    if (!form.label) {
      return null
    }

    return (
      <Form.Group className="card-input">
        <Form.Label>{form.label}</Form.Label>
        <Form.Control
          name={form.label}
          value={formData[form.question] ? formData[form.question] : ""}
          size="sm"
          type={"number"}
          placeholder={form.placeholder}
          onChange={e => handleFormChange(form.question, e.target.value, 0)}
        />
      </Form.Group>
    )
  }

  function validateFile(name, file) {
    let error = uploadError
    let hasError = false
    const validPdfTypes = ["application/pdf"]
    const validExcelTypes = [
      "application/x-msexcel",
      "application/vnd.ms-exce",
      "application/msexcel",
      "application/x-ms-excel",
      "application/x-excel",
      "application/x-dos_ms_excel",
      "application/xls",
      "application/x-xls",
    ]
    switch (name) {
      case "financial_model":
        if (
          !validPdfTypes.includes(file.type) &&
          !validExcelTypes.includes(file.type)
        ) {
          error[name] = "Invalid file type"
          hasError = true
        } else if (file.size > 5242880) {
          error[name] = "Invalid file size"
          hasError = true
        } else {
          error[name] = ""
          hasError = false
        }
        break
      case "business_plan":
      case "pitch_deck":
        if (!validPdfTypes.includes(file.type)) {
          error[name] = "Invalid file type"
          hasError = true
        } else if (file.size > 5242880) {
          error[name] = "Invalid file size"
          hasError = true
        } else {
          error[name] = ""
          hasError = false
        }
        break
      default:
        console.warn("validation not defined for " + name)
        break
    }

    return { hasError, error }
  }

  function handleDrop(name, files) {
    if (!files || !files.length) {
      setUploadError(
        Object.assign({}, { ...uploadError, [name]: "Invalid file" })
      )
      return
    }

    if (files.length > 1) {
      setUploadError(
        Object.assign(
          {},
          {
            ...uploadError,
            [name]: "Multi file upload is not allowed",
          }
        )
      )
      return
    }

    const file = files[0]
    const { hasError, error } = validateFile(name, file)
    if (hasError) {
      setUploadError(Object.assign({}, { ...uploadError, ...error }))
      return
    } else {
      setUploadError(Object.assign({}, { ...uploadError, [name]: "" }))
    }
    handleFormChange(name, file, false)
  }

  function handleFileRemove(e, name) {
    e.preventDefault()
    handleFormChange(name, {}, false)
    setUploadError(Object.assign({}, { ...uploadError, [name]: "" }))
  }

  function handleFileChange(e, name) {
    e.persist()
    const files = e.target.files || []
    handleDrop(name, files)
  }

  function hasUploadError () {
    const { pitch_deck, business_plan, financial_model } = uploadError
    const { pitch_deck: pitch_deck_data, business_plan: business_plan_data, financial_model: financial_model_data } = formData;
    if (pitch_deck || business_plan || financial_model) {
      return true
    } 
    if ((pitch_deck_data && !pitch_deck_data.name) || (business_plan_data && !business_plan_data.name) || (financial_model_data && !financial_model_data.name)) {
      return true
    }
    return false
    
  }

  const renderFileUpload = () => {
    if (step !== 15) {
      return <></>
    }

    return (
      <Row className="upload-row">
        <Col xs={12} md={4} className="upload-col">
          <p className="field-title">1. Pitch Deck</p>
          <h5 className="field-info">Format: PDF (5MB)</h5>

          {formData.pitch_deck && formData.pitch_deck.name ? (
            <>
              <div className="upload-mobile-preview d-lg-none">
                <div>
                  <div className="file-placeholder"></div>
                  <h5 className="file-name">{formData.pitch_deck.name}</h5>
                </div>
                <div>
                  <a
                    href="#remove-file"
                    className="btn btn-primary"
                    onClick={e => handleFileRemove(e, "pitch_deck")}
                  >
                    Remove
                  </a>
                </div>
              </div>
              <div className="upload-drag-box d-none d-lg-flex">
                <div className="file-placeholder"></div>
                <h5 className="file-name">{formData.pitch_deck.name}</h5>
                <a
                  href="#remove-file"
                  className="remove-file"
                  onClick={e => handleFileRemove(e, "pitch_deck")}
                >
                  <Image src="remove-icon.png" alt="remove" />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="btn btn-primary d-lg-none">
                <label htmlFor="pitch_deck">Upload file</label>
                <input
                  type="file"
                  id="pitch_deck"
                  name="pitch_deck"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "pitch_deck")
                  }}
                />
              </div>
              <DragAndDrop
                className="upload-drag-box d-none d-lg-flex"
                handleDrop={files => handleDrop("pitch_deck", files)}
              >
                <Image
                  fluid
                  src="upload-icon.png"
                  title="Upload"
                  alt="Upload Icon"
                />
                <h5>Drag and drop your file here</h5>
                <h5>OR</h5>
                <label htmlFor="pitch_deck" className="btn btn-primary">
                  Browse
                </label>
                <input
                  type="file"
                  name="pitch_deck"
                  id="pitch_deck"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "pitch_deck")
                  }}
                />
              </DragAndDrop>
            </>
          )}
          {uploadError.pitch_deck ? (
            <h5 className="field-error">{uploadError.pitch_deck}</h5>
          ) : (
            <></>
          )}
        </Col>

        <Col xs={12} md={4} className="upload-col">
          <p className="field-title">2. Business Plan</p>
          <h5 className="field-info">Format: PDF (5MB)</h5>

          {formData.business_plan && formData.business_plan.name ? (
            <>
              <div className="upload-mobile-preview d-lg-none">
                <div>
                  <div className="file-placeholder"></div>
                  <h5 className="file-name">{formData.business_plan.name}</h5>
                </div>
                <div>
                  <a
                    href="#remove-file"
                    className="btn btn-primary"
                    onClick={e => handleFileRemove(e, "business_plan")}
                  >
                    Remove
                  </a>
                </div>
              </div>
              <div className="upload-drag-box d-none d-lg-flex">
                <div className="file-placeholder"></div>
                <h5 className="file-name">{formData.business_plan.name}</h5>
                <a
                  href="#remove-file"
                  className="remove-file"
                  onClick={e => handleFileRemove(e, "business_plan")}
                >
                  <Image src="remove-icon.png" alt="remove" />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="btn btn-primary d-lg-none">
                <label htmlFor="business_plan">Upload file</label>
                <input
                  type="file"
                  id="business_plan"
                  name="business_plan"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "business_plan")
                  }}
                />
              </div>
              <DragAndDrop
                className="upload-drag-box d-none d-lg-flex"
                handleDrop={files => handleDrop("business_plan", files)}
              >
                <Image
                  fluid
                  src="upload-icon.png"
                  title="Upload"
                  alt="Upload Icon"
                />
                <h5>Drag and drop your file here</h5>
                <h5>OR</h5>
                <label htmlFor="business_plan" className="btn btn-primary">
                  Browse
                </label>
                <input
                  type="file"
                  name="business_plan"
                  id="business_plan"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "business_plan")
                  }}
                />
              </DragAndDrop>
            </>
          )}
          {uploadError.business_plan ? (
            <h5 className="field-error">{uploadError.business_plan}</h5>
          ) : (
            <></>
          )}
        </Col>

        <Col xs={12} md={4} className="upload-col">
          <p className="field-title">3. Financial Model / MIS</p>
          <h5 className="field-info">Format: PDF &amp; excel (5MB)</h5>

          {formData.financial_model && formData.financial_model.name ? (
            <>
              <div className="upload-mobile-preview d-lg-none">
                <div>
                  <div className="file-placeholder"></div>
                  <h5 className="file-name">{formData.financial_model.name}</h5>
                </div>
                <div>
                  <a
                    href="#remove-file"
                    className="btn btn-primary"
                    onClick={e => handleFileRemove(e, "financial_model")}
                  >
                    Remove
                  </a>
                </div>
              </div>
              <div className="upload-drag-box d-none d-lg-flex">
                <div className="file-placeholder"></div>
                <h5 className="file-name">{formData.financial_model.name}</h5>
                <a
                  href="#remove-file"
                  className="remove-file"
                  onClick={e => handleFileRemove(e, "financial_model")}
                >
                  <Image src="remove-icon.png" alt="remove" />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="btn btn-primary d-lg-none">
                <label htmlFor="financial_model">Upload file</label>
                <input
                  type="file"
                  id="financial_model"
                  name="financial_model"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "financial_model")
                  }}
                />
              </div>
              <DragAndDrop
                className="upload-drag-box d-none d-lg-flex"
                handleDrop={files => handleDrop("financial_model", files)}
              >
                <Image
                  fluid
                  src="upload-icon.png"
                  title="Upload"
                  alt="Upload Icon"
                />
                <h5>Drag and drop your file here</h5>
                <h5>OR</h5>
                <label htmlFor="financial_model" className="btn btn-primary">
                  Browse
                </label>
                <input
                  type="file"
                  name="financial_model"
                  id="financial_model"
                  accept=".pdf"
                  onChange={e => {
                    handleFileChange(e, "financial_model")
                  }}
                />
              </DragAndDrop>
            </>
          )}
          {uploadError.financial_model ? (
            <h5 className="field-error">{uploadError.financial_model}</h5>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <div className="form-container">
      <div className="question-item alfalaj-form">
        <h2 className="question">{form.question}</h2>
        {form.isMultiple ? <div className="help">(Multiple choice)</div> : ""}
        {renderInput()}
        {renderOptions()}
        {renderExtraForm()}
        {renderFileUpload()}
      </div>
      <div className="form-control-item">
        <div>
          <b>{step}/15</b> questions remaining{" "}
          <span className="aff-progress">
            <i style={{ width: (step / 15) * 100 + "%" }}></i>
          </span>
        </div>
        <div>
          <Button variant="outline-primary" onClick={() => handleStep("prev")}>
            Previous
          </Button>
          {step === 15 ? (
            <Button
              variant="primary"
              disabled={hasUploadError()}
              onClick={() => handleStep("next")}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="primary"
              disabled={!formData[form.question]}
              onClick={() => handleStep("next")}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionForm
