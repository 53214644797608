import React, { useState } from "react"
import ReactGA from "react-ga"
import { Button, Modal } from "react-bootstrap"
import { navigate } from "gatsby"

import Image from "@components/common/image"
import QuestionForm from "./question-form"
import BasicForm from "./basic-form"

const ApplyForFunding = () => {
  const [step, setStep] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [formData, setFormData] = useState({})

  const showQuestionForm = f => {
    ReactGA.event({
			category: 'steps-form',
			action: 'step1 completed'
		});
		setStep(2)
    setFormData({ ...formData, ...f })
  }

  const submitForm = (f, submit) => {
    setFormData({ ...formData, ...f })
    if (!submit) {
      setStep(1)
    } else {
      const fd = new FormData()
      const form = { ...formData, ...f }

      Object.keys(form).forEach(key => {
        if (["business_plan", "financial_model", "pitch_deck"].includes(key)) {
          fd.append(
            key,
            form[key],
            form[key].name || `${form[key]}_${Date.now()}`
          )
        } else {
          fd.append(key, form[key])
        }
      })

      const request = new XMLHttpRequest();
      request.open("POST", "https://alfalaj-investment.ae/sendMail.php", true)
      request.onload = function(event) {
        if (request.status === 200) {
          showSuccessModal();
        } else {
          console.log({request, event})
          showSuccessModal();
        }
      }
      request.send(fd);
      ReactGA.event({
				category: 'steps-form',
				action: 'form completed'
			})
      showSuccessModal();
    }
  }

  const showCancelModal = () => {
    setShowModal(true)
  }

  const hideCancelModal = () => {
    setShowModal(false)
  }

  const showSuccessModal = () => {
    setSuccessModal(true)
  }
  const hideSuccessModal = () => {
    setSuccessModal(false)
  }

  const goToHome = () => {
    navigate("/")
  }

  return (
    <section
      className={`apply-for-funding-form ${step === 1 ? "" : "questions-form"}`}
    >
      <div>
        <Image className="desktop" src="apply-for-funding-form.png" alt />
        <Image className="mobile" src="apply-for-funding-form-mobile.png" alt />
      </div>

      {step === 1 ? (
        <BasicForm formData={formData} submit={f => showQuestionForm(f)} />
      ) : null}
      {step === 2 ? (
        <QuestionForm
          formData={formData}
          submit={(f, cs) => submitForm(f, cs)}
        />
      ) : null}

      <span className="cancel" onClick={showCancelModal}>
        <Image src="cancel-icon2.png" alt />
      </span>
      <Modal size={"md"} show={showModal} onHide={hideCancelModal}>
        <Modal.Body className="cancel-modal">
          <h3>Are you sure you want to leave?</h3>
          <p>
            Do note that quitting now will discard all your changes in the form!
          </p>
          <Button variant="primary" onClick={goToHome}>
            Okay
          </Button>
        </Modal.Body>
      </Modal>

      <Modal size={"md"} show={successModal} onHide={hideSuccessModal}>
        <Modal.Body className="success-modal">
          <Image
            className="modal-icon"
            src="check-filled-circle.png"
            alt="success"
          />
          <h3>
            Your form has been <span>submitted!</span>
          </h3>
          <p>
            Thank you for your interest in becoming our partner. We will connect
            with you shortly.
          </p>
          <Button variant="primary" onClick={goToHome}>
            Okay
          </Button>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default ApplyForFunding
