import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form } from "react-bootstrap"

const BasicForm = props => {
  const [formData, setFormData] = useState({})
  const [formError, setFormError] = useState({})

  useEffect(() => {
    if (props.formData) {
      setFormData(props.formData)
    }
  }, [props.formData])

  const handleFormSubmit = () => {
    if (props.submit) {
      props.submit(formData)
    }
  }

  const handleFormChange = (key, value, multi) => {
    let fd = formData
    let v = fd[key]
    if (multi && !v) {
      v = []
    }

    if (multi && v.length > 0) {
      if (v.includes(value)) {
        v = v.filter(i => i !== value)
      } else {
        v.push(value)
      }
    } else if (multi && v.length === 0) {
      v.push(value)
    }

    if (!multi) {
      v = value
    }
    fd[key] = v
    setFormData(Object.assign({}, fd))
  }

  const handleValidation = (key, value) => {
    const fe = formError

    switch (key) {
      case "website":
        const websiteRegx = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
        if (!value) {
          fe[key] = "Please fill out this field"
        } else if (!websiteRegx.test(value)) {
          fe[key] = "Invalid website link"
        } else {
          fe[key] = ""
        }
        break

      case "founder_email":
        const emailRegx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (!value) {
          fe[key] = "Please fill out this field"
        } else if (!emailRegx.test(value)) {
          fe[key] = "Invalid email address"
        } else {
          fe[key] = ""
        }
        break

      default:
        if (!value) {
          fe[key] = "Please fill out this field"
        } else {
          fe[key] = ""
        }
        break
    }

    setFormError(Object.assign({}, fe))
  }

  return (
    <div className="form-container form-basic  alfalaj-form">
      <div>
        <h3>Let’s get to know you better!</h3>
        <p>
          The best way to get in touch with us is through filling this form.
          Tell us a little bit about yourself.
        </p>
        <Row>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>What’s your company name?</Form.Label>
              <Form.Control
                required
                size="md"
                value={formData["company"] ? formData["company"] : ""}
                type="text"
                placeholder="Company Name"
                onChange={e => handleFormChange("company", e.target.value, 0)}
                onBlur={e => handleValidation("company", e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${formError.company ? "d-block" : "d-none"}`}
              >
                {formError.company}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="hr-dotted">
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - -{" "}
        </div>

        <Row>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>Name of the Founder/CEO</Form.Label>
              <Form.Control
                required
                size="md"
                value={formData["founder"] ? formData["founder"] : ""}
                type="text"
                placeholder="Founder / CEO"
                onChange={e => handleFormChange("founder", e.target.value, 0)}
                onBlur={e => handleValidation("founder", e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${formError.founder ? "d-block" : "d-none"}`}
              >
                {formError.founder}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>What’s your website link?</Form.Label>
              <Form.Control
                required
                size="md"
                value={formData["website"] ? formData["website"] : ""}
                type="text"
                placeholder="Webpage"
                onChange={e => handleFormChange("website", e.target.value, 0)}
                onBlur={e => handleValidation("website", e.target.value)}
              />

              <Form.Control.Feedback
                type="invalid"
                className={`${formError.website ? "d-block" : "d-none"}`}
              >
                {formError.website}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>Email address of Founder/CEO</Form.Label>
              <Form.Control
                required
                size="md"
                value={
                  formData["founder_email"] ? formData["founder_email"] : ""
                }
                type="email"
                placeholder="Email Address"
                onChange={e =>
                  handleFormChange("founder_email", e.target.value, 0)
                }
                onBlur={e => handleValidation("founder_email", e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${formError.founder_email ? "d-block" : "d-none"}`}
              >
                {formError.founder_email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="hr-dotted">
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
          - - - - - - - - - - - - - - - - - - -{" "}
        </div>

        <Row>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>When was your company founded?</Form.Label>
              <Form.Control
                required
                size="md"
                value={formData["founded"] ? formData["founded"] : ""}
                type="date"
                onChange={e => handleFormChange("founded", e.target.value, 0)}
                onBlur={e => handleValidation("founded", e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${formError.founded ? "d-block" : "d-none"}`}
              >
                {formError.founded}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>Which country is your company based in?</Form.Label>
              <Form.Control
                required
                size="md"
                value={
                  formData["company_country"] ? formData["company_country"] : ""
                }
                type="text"
                placeholder="Country"
                onChange={e =>
                  handleFormChange("company_country", e.target.value, 0)
                }
                onBlur={e =>
                  handleValidation("company_country", e.target.value)
                }
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${
                  formError.company_country ? "d-block" : "d-none"
                }`}
              >
                {formError.company_country}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="field-wrapper">
            <Form.Group>
              <Form.Label>Which city is your company based in?</Form.Label>
              <Form.Control
                required
                size="md"
                value={formData["company_city"] ? formData["company_city"] : ""}
                type="text"
                placeholder="City"
                onChange={e =>
                  handleFormChange("company_city", e.target.value, 0)
                }
                onBlur={e => handleValidation("company_city", e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                className={`${formError.company_city ? "d-block" : "d-none"}`}
              >
                {formError.company_city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Button
          variant="primary"
          disabled={Object.keys(formData).length !== 7}
          onClick={handleFormSubmit}
        >
          Begin
        </Button>
      </div>
    </div>
  )
}

export default BasicForm
